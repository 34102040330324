import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import SEO from "../components/seo";
import BackButton from "../components/Core/BackButton";
import PageTitle from "../components/Core/PageTitle";
import { useContent } from "../hooks/useContent";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }
`

const CookiesPrivacy = () => {
    const headerConfig = {
        paddingBottom: 10,
    };

    const content = useContent()

    return (
        <>  
            <PageWrapper 
                headerConfig={headerConfig}
                hideCookieConsentBar={true}
            >
                <SEO title="Cookies and Privacy Policy" />
                <Content>
                    <Container className="justify-content-left">
                        <BackButton />
                        <PageTitle 
                            title={ content.get('privacy-statement-page-title', true) }
                        />
                        <Row className="justify-content-center">
                            <Col className="col-lg-8">
                                { content.get('cookies-and-privacy-statement', false) }
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default CookiesPrivacy;